import React,{useState} from "react"
import { Container, Row, Col } from "react-bootstrap"

// Hooks
import useWindowDimensions from "customHooks/useWindowDimensions"

// Styles
import "../Footer/Footer.scss"

// Elements
import FooterSearch from "./Elements/FooterSearch"
import FooterMenuListWeb from "./Elements/FooterMenuListWeb"
import FooterMenuListMobile from "./Elements/FooterMenuListMobile"
import FooterTerms from "./Elements/FooterTerms"
import FooterCopyRights from "./Elements/FooterCopyRights"
import FooterSocialLinks from "./Elements/FooterSocialLinks"
import FooterCompanies from "./Elements/FooterCompanies"

import { isLoggedIn} from "../../services/auth"

const Footer = ( {footerData, menu_slug} ) => {
    const [isAuth, setIsAuth] = useState(isLoggedIn())
    const { screenWidth } = useWindowDimensions();

    return (
        <>
            { screenWidth && (
                <div className="footer-wrapper">
                    <Container>
                        {!isAuth && <>
                        <FooterSearch slug={menu_slug}/>
                        <>
                            { ( screenWidth > 992 ) ? (
                                <FooterMenuListWeb
                                    footerMenuListData={ footerData.Footer_Menu_Component }
                                />
                            ) : (
                                <FooterMenuListMobile
                                    footerMenuListData={ footerData.Footer_Menu_Component }
                                />
                            ) }
                        </>
                        </>
                        }
                        <Row className={`footer-bottom-section ${isAuth ? "logged_in_foot" : ""}`}>
                            <Col
                                lg={ 9 }
                                sm={ 12 }
                                xs={ 12 }
                                className="footer-terms-section"
                            >
                                <FooterTerms
                                    footerTermsMenu={ footerData.Footer_Bottom_Links }
                                />
                                <FooterCopyRights />
                            </Col>
                            <Col
                                lg={ { order: 'last' } }
                                md={ { order: 'first' } }
                                xs={ { order: 'first' } }
                                className="footer-logos-section"
                            >   
                                {footerData?.site_social_icons?.display_icons_in_footer &&
                                    <FooterSocialLinks
                                        footerSocialLinksData={ footerData.site_social_icons?.icons_list }
                                    />
                                }
                                {footerData?.site_footer_logos?.display_logos_in_footer &&
                                    <FooterCompanies
                                        footerCompaniesData={ footerData.site_footer_logos.Footer_Logos }
                                    />
                                }
                            </Col>
                        </Row>
                    </Container>
                </div>
            ) }
        </>
    )
}

export default Footer