import axios from 'axios';
import { useContainerContext } from "../store/ContainerContext";



const baseURL = process.env.GATSBY_STRAPI_SRC;
//const baseURL = "https://resiland-strapi-dev.q.starberry.com";

// Without header call
const instance = axios.create({
  baseURL: baseURL,
  headers: {
    Authorization: `Bearer ${process.env.GATSBY_STRAPI_FORM_TOKEN}`,
  }
});

const reg_instance = axios.create({
  baseURL: baseURL,
  headers: {
    Authorization: `Bearer ${process.env.GATSBY_STRAPI_USER_REG_TOKEN}`,
  }
});
export const isBrowser = () => typeof window !== "undefined"
export const getUser = () =>
  isBrowser() && window.localStorage.getItem("puser")
    ? JSON.parse(window.localStorage.getItem("puser"))
    : {}

const setUser = (data, type) => {

  console.log('api => ', data)
  // Store token

  if (isBrowser()) {
    if(type === "login"){
      localStorage.setItem('pjwt', data.jwt)
      localStorage.setItem(
        'puser',
        JSON.stringify({
          suname: data.user.username,
          suid: data.user.id,
          logged_user_data: data.user
        })
      )
    } else{
      localStorage.setItem(
        'puser',
        JSON.stringify({
          suname: data.username,
          suid: data.id,
          logged_user_data: data
        })
      )
    }
    
  }
}

const removeUser = () => {
  // Store token
  if (isBrowser()) {
    localStorage.removeItem('pjwt');
    localStorage.removeItem('puser');
  }
}

// Login
export const handleLogin = ({ username, password }) => {
  //console.log('api => ', username, password, baseURL);
  return instance.post("/auth/local", {
    identifier: username,
    password: password,
  }).then((res) => {
    console.log("api => res ", res)
    // Auto confirm user
    if (res.data.user.confirmed) {

      if (res.data.user.role.name === 'Agents'){
        // Login success
        setUser(res.data, "login")
        return {
          user: res.data.user,
          message: res.statusText,
          status: res.status
        }
      } else {
        return {
          message: 'Invalid User Role!',
          status: 400
        }
      }
    }
  })
  .catch((error) => {
    // Login failed
    if (error.response) {
      // Login error
      return {
        //message: error.response.data.message[0].messages[0].message,
        message: "Email-ID or Password is Invalid!",
        status: error.response.status
      }
    } else {
      // Service error
      return {
        message: 'Api service Down!',
        status: 500
      }
    }
  })
  return false
}


// Register
export const handleRegister = (params) => {
  //console.log('params => ', params);
  return instance.post("/auth/local/register", params).then((res) => {
    console.log("api => res ", res)
    // Auto confirm user
    setUser(res.data, "user_details")
    return res;
  })
  .catch((error) => {
    // failed
    if (error.response) {
      // error
      console.log("api => error_response", error.response)
      return {
        message: error.response.data.message.length > 0 ? error.response.data.message[0].messages[0].message : error.response.data.message,
        //message: error.response.message,
        status: error.response.status
      }
    } else {
      // Service error
      return {
        message: 'Api service Down!',
        status: 500
      }
    }
  })
  return false
}


// Update User Data
export const UpdateUserData = (params, user_id) => {
  //console.log('params => ', params);
  return instance.post("/auth/local/register", params).then((res) => {
    console.log("api => res ", res)
    // Auto confirm user
    setUser(res.data, "user_details")
    return res;
  })
  .catch((error) => {
    // failed
    if (error.response) {
      // error
      console.log("api => error_response", error.response)
      return {
        message: error.response.data.message.length > 0 ? error.response.data.message[0].messages[0].message : error.response.data.message,
        //message: error.response.message,
        status: error.response.status
      }
    } else {
      // Service error
      return {
        message: 'Api service Down!',
        status: 500
      }
    }
  })
  return false
}


// Forgot Password
export const forgotPassword = ({ username }) => {
  //console.log('api => ', username, baseURL);
  return instance.post("/auth/forgot-password", {
    email: username,
  }).then((res) => {
    //console.log("api => res ", res)
    // Auto confirm user
    if (res.status === 200) {

      if (res.statusText === 'OK'){
        return {
          user: username,
          message: res.statusText,
          status: res.status
        }
      } else {
        return {
          message: 'Invalid User Role!',
          status: 400
        }
      }
    }
  }).catch((error) => {
    // Login failed
    if (error.response) {
      // Login error
      return {
        //message: error.response.data.message[0].messages[0].message,
        message: "Email-ID is Invalid!",
        status: error.response.status
      }
    } else {
      // Service error
      return {
        message: 'Api service Down!',
        status: 500
      }
    }
  })
  return false
}

export const resetPassword = ({ new_password, reset_pwd_code }) => {
  //console.log('api => ', new_password, reset_pwd_code, baseURL);
  return instance.post("/auth/reset-password", {
    code: reset_pwd_code, // code contained in the reset link of step 3.
    password: new_password,
    passwordConfirmation: new_password,
  }).then((res) => {
    //console.log("api => res ", res)
    // Auto confirm user
    if (res.status === 200) {

      if (res.statusText === 'OK'){
        return {
          message: res.statusText,
          status: res.status
        }
      } else {
        return {
          message: 'Invalid User Role!',
          status: 400
        }
      }
    }
  }).catch((error) => {
    // Login failed
    if (error.response) {
      // Login error
      return {
        message: error.response.data.message[0].messages[0].message,
        //message: "Email-ID is Invalid!",
        status: error.response.status
      }
    } else {
      // Service error
      return {
        message: 'Api service Down!',
        status: 500
      }
    }
  })
  return false
}

export const isLoggedIn = () => {
  const user = getUser();
  //console.log("api user => ", user);

  return !!user.suname
}
export const logout = callback => {
  removeUser()
}
